import { computed, onBeforeUnmount, ref } from '@nuxtjs/composition-api';

type TimeoutId = ReturnType<typeof setTimeout> | null;

export function useRepeatAction(action: () => void, initialDelay = 0, interval = 500) {
  const timeoutId = ref<TimeoutId>(null);

  const startRepeat = () => {
    if (timeoutId.value !== null) {
      return;
    }

    timeoutId.value = setTimeout(() => {
      action();
      const repeatAction = () => {
        action();
        timeoutId.value = setTimeout(repeatAction, interval);
      };
      timeoutId.value = setTimeout(repeatAction, interval);
    }, initialDelay) as TimeoutId;
  };

  const clearRepeat = () => {
    if (timeoutId.value !== null) {
      clearTimeout(timeoutId.value as TimeoutId);
      timeoutId.value = null;
    }
  };

  const isRepeating = computed(() => !!timeoutId.value);

  onBeforeUnmount(() => {
    clearRepeat();
  });

  return {
    startRepeat,
    clearRepeat,
    isRepeating
  };
}
